<template>
  <b-container fluid>
    <BADCCertificateViewEn :id="id" :circular_memo_no="circular_memo_no"/>
    <!-- <slot v-if="$i18n.locale === 'en'" >
      <BADCCertificateViewEn :id="id" :circular_memo_no="circular_memo_no"/>
    </slot>
    <slot v-else>
      <BADCCertificateViewBn :id="id" :circular_memo_no="circular_memo_no"/>
  </slot> -->
  </b-container>
</template>
<script>
import BADCCertificateViewEn from './BADCCertificateViewEn'
// import BADCCertificateViewBn from './BADCCertificateViewBn'

export default {
  components: {
    BADCCertificateViewEn
    // BADCCertificateViewBn
  },
  data () {
    return {
      id: this.$route.query.id,
      circular_memo_no: this.$route.query.circular_memo_no
    }
  }
}
</script>
